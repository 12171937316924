<template lang="pug">
  .EvaluationPriority.Container(@click="actionActive" :style="isAtril ? 'margin: auto; width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 96.38px);' : 'width: 100%; font-size: 1.3rem;'")
    .Content
      article.Article(:style="!isAtril ? 'color: #545454;' : 'color: #545454; margin-top: 40px; width: 400px; max-width: 100%;'")
        .card-box-white
          svg(width='21' height='21' viewbox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 1.49637e-06 10.5 9.8941e-07C4.70102 4.82446e-07 3.33225e-06 4.70101 2.82529e-06 10.5C0.00615352 16.2964 4.70356 20.9938 10.5 21Z' fill='#3290E0')
            path(d='M10.4993 16.3906C11.2513 16.3906 11.8203 15.9982 11.8203 15.3642L11.8203 9.54988C11.8203 8.9153 11.2514 8.52416 10.4993 8.52416C9.76557 8.52416 9.17819 8.93167 9.17819 9.54988L9.17819 15.3642C9.17826 15.9831 9.76556 16.3906 10.4993 16.3906ZM10.4993 7.21426C11.2218 7.21426 11.8098 6.62622 11.8098 5.90311C11.8098 5.18124 11.2218 4.5932 10.4993 4.5932C9.77672 4.5932 9.18935 5.18124 9.18935 5.90311C9.18942 6.62629 9.77672 7.21426 10.4993 7.21426Z' fill='#F4F4F4')
          p.text-center.text-success-local.titleCard Si eres una persona:
          ul.listUnderlined.m-4.mt-0.mx-auto
            li Mayor de
              strong.ml-1 60 años
            li En estado de
              strong.ml-1 embarazo
            li En condición de
              strong.ml-1 discapacidad
          p.mb-2.mt-2.text-center.mr-2.ml-2.derecho Tienes derecho a:
          div(style=`
            padding-bottom: 20px;
            margin-top: 1.3rem;
          `)
            button(size="sm" type="button" @click="confirmedAction").py-2.buttonBlueContinue.button-without-styles.mx-auto.d-block
              span.my-1.d-block Aceptar
        p(style=`
          line-height: 1;
          margin-top: 0.1rem;
          text-align: center;
          margin: 1rem auto;
        `)
          small.ruleSmall.mx-auto.mt-5.mb-4
            | Entre todos debemos proteger, promover, respetar y defender los derechos de esta población (Circular única 049 de la Supersalud)
        button.text-center.buttonOutlined(type="button" @click="goBack")
          span Retornar
    .buttons.Footer(:style="colorSecondaryComputed")
      .d-flex.justify-content-between
        b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goBack" :style="isAtril ? `font-size: 1.5rem !important; ${colorComputed}` : colorComputed")
          Back(size="24px")
          span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Atrás
</template>

<script>
import { mapActions, mapState } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import WheelBarrow from "mdi-vue/WheelchairAccessibility";

export default {
  name: "EvaluationPriority",

  components: {
    Header,
    Back,
    WheelBarrow
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      previousView: state => state.virtualrow.previousView,
      isAtril: state => state.virtualrow.isAtril,
      currentSite: state => state.virtualrow.currentSite,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      secondColorBlue: state => state.virtualrow.secondColorBlue
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goBack: "virtualrow/goBack",
      confirmedAction: "virtualrow/confirmedAction"
    }),

    dontCallToEmergency() {
      this.priority = false;
    },

    actionActive() {
      if (this.isAtril) this.$store.dispatch("setTimer");
    }
  }
};
</script>

<style>
.EvaluationPriority {
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.EvaluationPriority > div {
  display: table-cell;
  vertical-align: middle;
}

.Article {
  max-width: 400px;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.titleCard {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin: auto;
  display: block;
  height: auto;

  color: #3290e0;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

.derecho {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #081e3d;
}

.blue-dk {
  color: #00599d;
}

.image {
  width: 100%;
  object-fit: cover;
}

.dk-pos-dk.isAtril {
  top: -90%;
}
.dk-pos-dk {
  position: absolute;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.card-box-white {
  padding: 1rem;
  filter: drop-shadow(0px 4.33129px 8.66258px rgba(21, 21, 21, 0.08));
  background: #f4f4f4;
  border-radius: 23px;
}

.listUnderlined {
  text-align: start;
  width: fit-content;

  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #081e3d;
}

.buttonOutlined {
  width: 317.25px;
  padding: 0.5rem 1.8rem;
  border: 1.19718px solid #009fe3;
  box-sizing: border-box;
  border-radius: 23.9437px;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #009fe3;
}

.ruleSmall {
  display: block;
  max-width: 281.53px;
  width: 100%;

  font-style: normal;
  text-align: center;
  font-weight: 400;
  font-size: 15.1595px;
  line-height: 19px;

  color: #788591;
}

.buttonBlueContinue {
  width: 100%;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  color: #f4f4f4;
  background: #009fe3;
  border-radius: 23.9437px;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .image {
    width: 50%;
  }
}
</style>
