<template lang="pug">
  .Principal
    .panel
      slot
</template>

<script>
export default {
  name: "Panel"
};
</script>

<style>
.Principal {
  max-width: 450px;
  display: block;
  margin: auto;
  margin-top: 10px;
}

.panel {
  border-radius: 5px;
  display: grid;
  font-weight: bold;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 347px;
  margin: auto;
  gap: 4.8px;
}

.item-panel {
  padding: 1rem;
  border-left: 2.5px solid #f2f4f8;
  cursor: pointer;
  text-align: center;
  border-right: 2.5px solid #f2f4f8;
  color: #f2f4f8;
}

.item-panel.bigbutton {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 25px;
  padding: 1.25rem;

  color: #000000;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.item-panel:last-of-type {
  background-color: #009fe3;
  color: white;
  transition: 0.25s all;
}

.item-panel.item-panel-delete_button {
  background: #c9c9c9;
  border: none;
}
/*
.item-panel:last-of-type:hover {
  background-color: #014579;
} */
</style>
