<template lang="pug">
.CurrentUser.Container(:style="isAtril ? 'color: #545454; max-width: 1012px; margin: auto; height: calc(100vh - 173px);' : 'color: #545454; font-size: 1.3rem;'")
  .Content
    .text-center.mb-5
      svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -1.57992e-06 16 -2.35244e-06C7.16345 -3.12495e-06 1.21761e-06 7.16344 4.4509e-07 16C0.00937469 24.8326 7.16733 31.9906 16 32Z' fill='#3290E0')
        path(d='M15.9987 24.9844C17.1446 24.9844 18.0117 24.3863 18.0117 23.4204L18.0117 14.5604C18.0117 13.5934 17.1448 12.9974 15.9987 12.9974C14.8807 12.9974 13.9856 13.6184 13.9856 14.5604L13.9856 23.4204C13.9857 24.3634 14.8807 24.9844 15.9987 24.9844ZM15.9987 11.0013C17.0997 11.0013 17.9957 10.1053 17.9957 9.00339C17.9957 7.90341 17.0997 7.00735 15.9987 7.00735C14.8977 7.00735 14.0026 7.90341 14.0026 9.00339C14.0027 10.1054 14.8977 11.0013 15.9987 11.0013Z' fill='#F4F4F4')
      p.text-success-local.text-center.d-block.mx-auto.mb-4.fonter
        strong.mr-1 {{ currentUser.Nombres || "El dolcumento"}}
        | no tiene un plan activo. Puedes continuar con estos datos, o reingresar nuevos datos
    .boxInformative.pb-5
      p
        span Nombre:
        strong.ml-1 {{ currentUser.Nombres || "No registra"}}
      p.pb-3
        span Documento:
        strong.ml-1 {{ currentUser.NumeroIdentificacion || "No registra"}}
      button(
        @click="goNoDate"
      ).text-center.buttonServiceContinue.mt-5
        span
          | Continuar
    button(
      @click="cleanAndBack"
    ).text-center.outlinedServiceButtonColmedica.mt-5.py-2
      span.my-1
        | Reingresar datos
    //- button(
    //-   @click="goBack"
    //- ).text-center.buttonService.mt-3.outlinedServiceButtonColmedica
    //-   span
    //-     | Ingresar nuevos datos
  //-   div(style="text-align: center;").mt-3
  //-     div(style="position: relative; height: 66px; margin-bottom: 27px;").mt-5
  //-       span.text-center.mt-2.h1(:class="isAtril ? 'dk-pos-dk' : 'dk-font'" style="font-weight: bold !important;") {{ siteIsOffice ? "Oficina" : "Centro Médico" }} {{ siteName }}
  //-     HelpIcon.gray(:size="isAtril ? 70 : 50" :style="isAtril ? 'margin-top: 36px;' : ''")
  //-     div.text-center.dk-othre.d-flex.justify-content-center
  //-       p.mt-3(:style="isAtril ? 'max-width: 670px;' : ''") Este documento no tiene un plan activo. Puedes continuar con estos datos, o digitar nuevos datos
  //-   .big_Informative(:style="isAtril ? 'width: 400px; margin: 0 auto;' : 'width: 100%;'")
  //-     .dk-overlay
  //-       p(v-if="currentUser ? currentUser.Nombres : currentUser") {{ currentUser.Nombres }}
  //-       p Documento: {{ currentUser ? currentUser.TipoIdentificacion : "" }} {{ currentUser ? currentUser.NumeroIdentificacion : "" }}
  //-   b-button.mt-4(variant="bluecolmedica" @click="cleanAndBack" :style="isAtril ? 'font-size: 1.7rem; margin: 0 auto; display: block; width: 100%; max-width: 350px;' + colorComputed : 'width: 100%; max-width: 208px; font-size: 1.2rem !important; margin: 20px auto; display: block;' + colorComputed") Digitar nuevos datos
  //-   b-button.mt-4(
  //-     variant="bluecolmedica",
  //-     @click="goNoDate",
  //-     :style="isAtril ? 'font-size: 1.7rem; margin: auto; display: block; width: 100%; max-width: 350px;' + colorComputed : 'font-size: 1.2rem !important; max-width: 208px; width: 100%; margin: auto; display: block; margin-bottom: 3rem; ' + colorComputed"
  //-   )
  //-     div(v-if="!!!charging")
  //-       span
  //-         | Continuar con estos datos
  //-       //- span
  //-       //-   Continue(size="24px")
  //-     div(v-else)
  //-       MiniSpinner
  //- .buttons.Footer(:style="colorSecondaryComputed").p-4
  //-   .d-flex.justify-content-between
</template>

<script>
import { mapActions, mapState } from "vuex";

import infoSvgIcon from "@/assets/infoSvgIcon.svg";

import HelpIcon from "mdi-vue/HelpCircle.vue";
import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";

export default {
  name: "Help",

  data: () => ({
    infoSvgIcon
  }),

  watch: {
    currentUser(val) {
      if (!val) this.goBack();
    }
  },

  components: {
    Header,
    HelpIcon,
    Continue,
    MiniSpinner
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrow.currentUser,
      charging: state => state.virtualrow.charging,
      currentSite: state => state.virtualrow.currentSite,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      options: state => state.virtualrow.tipide
    }),
    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },
    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },
    siteIsOffice() {
      return this.currentSite?.type === "of";
    },
    siteName() {
      return this.currentSite?.name;
    },
    optionSelected() {
      return this.options.find(
        option => option.value === this.currentUser.TipoIdentificacion
      );
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow/goToView",
      goBack: "virtualrow/goBack",
      cleanCurrentUser: "virtualrow/cleanCurrentUser"
    }),

    cleanAndBack() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.cleanCurrentUser();
      this.goBack();
    },

    goNoDate() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "NoDate" });
    }
  }
};
</script>

<style scoped>
.CurrentUser {
  height: calc(100vh - 173px);
  overflow: auto;
  margin: auto;
  display: flex;
  justify-content: center;
}

.CurrentUser > div {
  display: table-cell;
  vertical-align: middle;
}

.gray {
  color: gray;
}

.big_Informative {
  position: relative;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.dk-font {
  font-size: 1.3rem;
}

.dk-pos-dk {
  position: absolute;
  top: -45%;
  left: 0px;
  transition: 1s;
  right: 0px;
  font-size: 2.8rem;
}

.boxInformative {
  width: 400px;
  margin-top: 34px !important;
  padding: 1rem;

  text-align: start;
  margin: auto;
  display: block;

  background: #f4f4f4;
  filter: drop-shadow(0px 7.14373px 14.2875px rgba(21, 21, 21, 0.08));
  border-radius: 16.2385px;
}

.boxInformative p:last-child {
  margin-bottom: 0;
}

.boxInformative > p {
  margin-bottom: 0.25rem;
  font-size: 20px;
  line-height: 26px;

  color: #081e3d;
}

.buttonServiceContinue {
  width: 100%;
  max-width: 112px;
  padding: 9.5px 20px;

  position: absolute;
  right: 17px;
  bottom: 17px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  border-radius: 23.9437px;

  border: none;
  outline: none;
  text-align: center;

  transition: 1s background-color;
  color: #ffffff;
  background: #009fe3;
}

.buttonServiceContinue:disabled {
  background-color: #788591;
}

.buttonServiceContinue.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.outlinedServiceButtonColmedica {
  width: 100%;
  max-width: 317.25px;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  border: 1.19718px solid #009fe3;
  box-sizing: border-box;
  border-radius: 23.9437px;

  color: #009fe3;
  background: none;
}

.infoSvgIcon {
  width: 48px;
  height: 48px;
}

.fonter {
  width: 370px;

  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #3290e0;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
