<template lang="pug">
.CurrentUser.d-flex.justify-content-center(:style="isAtril && `width: 100%; overflow: hidden; max-width: 1000px; height: calc(100vh - 96.38px);`")
  .Content
    div.box-dk.mt-4.mb-5.pt-2.text-center.with-mx
      h3.fonterH3Colmedica.mx-auto.d-block Ingreso de datos
      p.textHeroColmedica.mb-2.m-0.mx-auto.d-block.mt-2
        | Digita los datos de la persona que va a acceder al servicio
    form#validateUser(@submit.prevent="sendData", autocomplete="off", v-if="!!!currentUser" :style="isAtril ? 'margin-top: 30px;' : 'padding-bottom: 100px;'")
      div(style="max-width: 347px; margin: auto; display: block;")
        .form-select-container
          b-form-select.Input-text.mb-4(
            v-model="models.TipoIdentificacion",
            :options="options",
            size="sm",
            autocomplete="off",
            required,
          )
          svg(width='17' height='10' viewbox='0 0 17 10' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M15.4844 1.48438L8.48483 8.48392' stroke='#0058A2' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
            path(d='M1.51562 1.48438L8.51517 8.48392' stroke='#0058A2' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
        input.Input-text.mt-4(
          type="number",
          v-model.number="models.NumeroIdentificacion",
          min="0"
          placeholder="Número",
          required,
          autocomplete="off",
          :disabled="isAtril"
        )#cedula_number
    .pb-5.mt-3(v-if="!!currentUser")
      .pb-5
        .big_box
          .dk-overlay
            p(v-if="currentUser.Nombres") {{ currentUser.Nombres }}
            p.pb-5 Documento: {{ currentUser.TipoIdentificacion }} {{ currentUser.NumeroIdentificacion }}
    .mt-4.mb-2.pt-1(v-if="isAtril")
      Panel
        .item-panel.bigbutton(@click="printPanel(1)") 1
        .item-panel.bigbutton(@click="printPanel(2)") 2
        .item-panel.bigbutton(@click="printPanel(3)") 3
        .item-panel.bigbutton(@click="printPanel(4)") 4
        .item-panel.bigbutton(@click="printPanel(5)") 5
        //- 347px
        .item-panel.bigbutton(@click="printPanel(6)") 6
        .item-panel.bigbutton(@click="printPanel(7)") 7
        .item-panel.bigbutton(@click="printPanel(8)") 8
        .item-panel.bigbutton(@click="printPanel(9)") 9
        //- .item-panel(@click="printPanel('<')" style="background: #3a8ac0; color: white;") Borrar
        .item-panel.bigbutton.item-panel-delete_button(@click="printPanel('<')")
          img(:src="IconBackDelete" alt="IconBackDelete").IconBackDelete
        .item-panel.bigbutton.zeroButton(@click="printPanel(0)") 0
        button.button-without-styles.item-panel.bigbutton(
          @click="onGoToNextView"
          :disabled="!!charging"
          :class="(!!charging || !models.NumeroIdentificacion) && 'disabled'"
        )
          div(v-if="!!!charging")
            svg(width='19' height='13' viewbox='0 0 19 13' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M0.109375 5.67969H14.2794L10.6994 2.08969L12.1094 0.679688L18.1094 6.67969L12.1094 12.6797L10.6994 11.2697L14.2794 7.67969H0.109375V5.67969Z' fill='#F4F4F4')
          div(v-else)
            MiniSpinner
  .buttons.Footer(:style="colorSecondaryComputed")
    #d-flex.d-flex.align-items-center(:class="!!currentUser ? 'justify-content-center' : 'justify-content-end'" v-if="!isAtril")
      b-button.text-center(
        v-if="!!!currentUser"
        variant="bluecolmedica",
        :style="colorComputed"
        form="validateUser"
        size="sm",
        type="submit",
        :disabled="!this.newUserReady || !!charging"
      )
        span(v-if="!!!charging")
          span.text-sizing-12 Continuar
          Continue(size="24px")
        div(v-else)
          MiniSpinner
      b-button.text-center(
        v-else
        :disabled="!!charging"
        variant="bluecolmedica",
        size="sm",
        @click="cleanCurrentUser"
        type="button"
        :style="colorComputed"
      )
        span.text-sizing-12 Continuar con otro usuario
        Continue(size="24px")
    #d-flex.d-flex.align-items-start(v-else)
      b-button.text-center(
        @click="goBack",
        variant="bluecolmedica",
        :style="colorComputed"
        size="sm",
        type="submit",
      )
        Atras(size="24px")
        span.text-sizing-12 Atrás
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";
import Panel from "./components/Panel";
import Atras from "mdi-vue/ArrowLeftBold";

import IconBackDelete from "@/assets/newDesign/IconBackDelete.svg";

export default {
  name: "Home",
  components: {
    Header,
    Continue,
    MiniSpinner,
    Panel,
    Atras
  },

  created() {
    if (this.isAtril) {
      this.$store.dispatch("setTimer");
      this.cleanCurrentUser();
    }
  },

  data: () => ({
    myForm: "",
    captchaElement: null,
    isCapcha: true,
    models: {
      NumeroIdentificacion: "",
      TipoIdentificacion: "CC"
    },
    IconBackDelete
  }),

  watch: {
    "models.TipoIdentificacion"(val) {
      if (val) {
        if (this.isAtril) {
          this.$store.dispatch("setTimer");
        }
      }
    }
  },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrow.currentSite,
      options: state => state.virtualrow.tipide,
      currentUser: state => state.virtualrow.currentUser,
      citas: state => state.virtualrow.citas,
      charging: state => state.virtualrow.charging,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      error: state => state.virtualrow.error
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    toSend() {
      return { ...this.models, CodigoOficina: this.$route.params.code };
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    newUserReady() {
      return !!(
        this.models.NumeroIdentificacion && this.models.TipoIdentificacion
      );
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrow/goToView",
      goBack: "virtualrow/goBack",
      authenticate: "virtualrow/authenticate",
      cleanCurrentUser: "virtualrow/cleanCurrentUser",
      removeTimer: "removeTimer",
      setTimer: "setTimer",
      sendTracking: "virtualrow/handleTracking"
    }),
    continueWithExistent() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.models.TipoIdentificacion = this.currentUser.TipoIdentificacion;
      this.models.NumeroIdentificacion = this.currentUser.NumeroIdentificacion;
      this.sendData();
    },
    printPanel(key) {
      if (this.isAtril) {
        this.$store.dispatch("setTimer");
      }
      if (key === "<") {
        this.models.NumeroIdentificacion = this.models.NumeroIdentificacion.substring(
          0,
          this.models.NumeroIdentificacion.length - 1
        );
        return;
      }
      if (key == "Ok") {
        if (
          !this.models.NumeroIdentificacion ||
          !this.models.TipoIdentificacion
        )
          return;
        return this.sendData();
      }
      this.models.NumeroIdentificacion += key;
    },
    onGoToNextView() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.sendData();
    },
    async sendData() {
      document.getElementById("cedula_number")?.blur();
      if (this.isAtril) await this.removeTimer();
      await this.authenticate(this.toSend);
      if (this.isAtril) await this.setTimer();

      // tracking
      setTimeout(() => {
        let origin = "fv_";
        if (location.hash.includes("fv_atril")) origin = "fv_atril_";
        let auth = this.error
          ? " autentitacion-no-cliente"
          : "autenticacion-cliente";
        let trackingData = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: "",
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 2000);
    }
  }
};
</script>

<style scoped>
.Content {
  position: relative;
}

.CurrentUser {
  margin: auto;
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.CurrentUser > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
}

.box-dk {
  margin: auto;
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 12300000;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 16px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  /* color: #fff; */
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

#d-flex span svg {
  width: 20px;
  height: 20px;
  /* fill: white; */
}

.big_box {
  position: relative;
}

.dk-link {
  outline: none;
  border: none;
  appearance: none;
  text-decoration: none;
  background: none;
  /* color: #6565; */
  color: #6558f5;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin: 93px auto;
}

.dk-link:hover {
  text-decoration: underline;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.disabled {
  cursor: not-allowed;
  background: #0058a0a4;
}

.disabled:hover {
  background: #0058a0cb;
}

.dk-pos-dk {
  position: absolute;
  top: 30%;
  left: 0px;
  font-weight: bold;
  transition: 1s;
  right: 0px;
}

.Input-text::placeholder {
  color: rgb(0 0 0) !important;
}

.form-select-container {
  position: relative;
}

.form-select-container svg {
  width: 27px;

  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);

  background: #f4f4f4;
}

.h1 {
  font-size: 2.8rem;
}

.buttonService {
  width: 100%;
  max-width: 300.83px;
  padding: 1rem 2rem;
  background-color: #009dac;
  border-radius: 16.4277px;
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
}

.buttonService:disabled {
  background-color: #788591;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.zeroButton {
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0px 1.40556px 0px rgb(0 0 0 / 25%);
}

.disabled {
  opacity: 0.5;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
