<template lang="pug">
.Home.Container2.align-items-flex-start(style="color: #545454; max-width: 1012px; margin: auto; width: 100%;")
  .Content
    .d-flex.pt-2(
      style="flex-direction: column; align-items: center; height: 100%;"
    )
      article(style="width: 100%;")
        .boxTextHeader(:class="isCenter ? 'without-mx' : 'with-mx'")
          h3.fonterH3Colmedica {{ isCenter ? `Bienvenido a ${siteName} ` : "Hola, bienvenido" }}
          p.textHeroColmedica.mt-1 Puedes tomar tu turno escaneando este código QR con tu celular
        .d-flex.containerBoxes.align-items-center.justify-content-center
          .box
            .containerQr
              .canvas_bordered
                svg.svg(width='220' height='203' viewbox='0 0 220 203' fill='none' xmlns='http://www.w3.org/2000/svg')
                  path(d='M1 19.0213V1.54688H17.3823' stroke='#3290E0' stroke-width='1.09215')
                  path(d='M218.885 184.479L218.885 201.953L202.503 201.953' stroke='#3290E0' stroke-width='1.09215')
                  path(d='M201.41 0.999999L218.885 1L218.885 17.3823' stroke='#3290E0' stroke-width='1.09215')
                  path(d='M18.4744 201.406L1 201.406L1 185.024' stroke='#3290E0' stroke-width='1.09215')
                .canvas_container
                  #qr
        p.qrDescription.mt-3.pt-1 O presiona continuar para tomar un turno impreso
        button(
          style="display: block; margin: auto;"
          @click="goCurrent"
        ).button-without-styles.buttonContinue.mt-4
          | Continuar
  .Footer
</template>

<script>
import { mapState } from "vuex";
import QrCode from "easyqrcodejs";

import InfoQRView from "@/assets/infoQRView.svg";
import InfoQRView1 from "@/assets/infoQRView1.svg";

export default {
  name: "Home",
  data() {
    return {
      qrcode: null,
      text: "",
      changeColor: "",
      changeColorText: "",
      changeColorThree: "",
      InfoQRView,
      InfoQRView1
    };
  },
  mounted() {
    this.$store.dispatch("turns/cancelTurnAction", true);
    this.text = `${location.origin}/#/fv/${this.$route.params.code}${
      this.$route.query.area ? "?area=" + this.$route.query.area : ""
    }`;
    // eslint-disable-next-line no-unused-vars
    this.qrcode = new QrCode(document.getElementById("qr"), {
      text: this.text,
      width: 120,
      height: 120,
      colorDark: "black",
      colorLight: "#FFFFFF"
    });
  },

  watch: {
    primaryColorBlue() {
      document
        .getElementById("qr")
        .querySelector("canvas")
        ?.remove();
      this.qrcode = new QrCode(document.getElementById("qr"), {
        text: this.text,
        width: 120,
        height: 120,
        colorDark: "black",
        colorLight: "#FFFFFF"
      });
    }
  },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrow.currentSite,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    isCenter() {
      return false;
    }
  },

  methods: {
    goCurrent() {
      this.$store.dispatch("virtualrow/goToView", {
        view: "Home"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.Content {
  position: relative;
  margin-top: 2rem;
}

.Home {
  height: calc(100vh - 96.38px);
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: start;
}

.Home > div {
  display: table-cell;
  vertical-align: middle;
}

.p {
  margin: 1rem auto;
  margin-top: 5rem;
  width: 262.12px;
  height: 51px;

  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  /* or 144% */

  text-align: center;

  /* TEXT/Sub títulos, body, íconos. */

  color: #788591;
}

.dk-pos {
  position: fixed;
  top: 0;
  right: 0;
}

.canvas_bordered {
  position: relative;
  svg.svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -10px;
  }
}

.buttonGreen {
  width: 320px;
  height: 60.07px;

  background: #009dac;
  border-radius: 17.4744px;
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  /* identical to box height, or 144% */

  text-align: center;

  /* TEXT/Uso: texto en fondo oscuro y contraste con imagen. */

  color: #ffffff;
  border: none;
}

.canvas_container {
  max-width: 169.28px;
  max-height: 169.28px;

  margin: 0px auto;
  padding-top: 1.1rem;
  padding-bottom: 0.8rem;

  display: flex;
  justify-content: center;

  position: relative;
  top: 7px;

  background: white;
  #canvas {
    width: 170px !important;
    height: 170px !important;
    object-fit: contain;
  }
}

.dk-pos-dk {
  position: absolute;
  top: -90%;
  left: 0px;
  transition: 1s;
  right: 0px;
  font-weight: bold;
  font-size: 2.8rem;
}

.box {
  max-width: 320px;
  width: 100%;
  height: 288px;

  padding: 0;
  padding-bottom: 4rem;
  padding-top: 3.25rem;
  position: relative;

  background: #f4f4f4;
  /* DROP */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 29px;
}

.qrDescription {
  max-width: 317.25px;
  margin: auto;
  text-align: center;

  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;

  color: #788591;
}

.buttonContinue {
  max-width: 317.25px;
  width: 100%;
  padding: 0.7rem;

  font-weight: 500;
  font-size: 20px;
  line-height: 27px;

  display: flex;
  align-items: center;
  text-align: center;

  color: #f4f4f4;

  background: #009fe3;
  border-radius: 23.9437px;
}

.InfoQRView1 {
  margin-bottom: 3rem;
}

.containerQR {
  height: 100%;
  padding: 0.5rem;
}

.textHeroColmedica {
  max-width: 80%;
  margin: auto;
  margin-bottom: 26px;
}

.boxTextHeader {
  max-width: 70%;
  margin: auto;
}

.with-mx {
  max-width: 70%;
}

.without-mx {
  max-width: 100%;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
