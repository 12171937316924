<template lang="pug">
.NoDate.Container(:style="isAtril ? 'color: #545454; max-width: 1800px; width: 100%; margin: auto; height: calc(100vh - 96.38px); min-height: 600px;' : 'margin: auto; width: 100%; font-size: 1.3rem;'")
  .Content
    h3.fonterH3Colmedica.fonterH3ColmedicaNoDate.mb-4 ¿Cuál es el motivo de tu asistencia?
    .buttons-dk(:style="isAtril ? 'margin-top: 80px; max-width: 400px; margin: auto;' : 'margin: 30px auto;'")
      template(v-for="act in actionToShow")
        button.arrowButton.mb-3.p-4.d-flex.justify-content-between.align-items-center(
          v-if="!!!act.hidden"
          :key="act.actionCode"
          :disabled="!!act.disabled"
          block
          @click="calculateAction(act)"
        )
          span(v-html="act.label").text-center
          svg.mr-2(width='12' height='20' viewbox='0 0 12 20' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M2.26562 2.125L10.3343 9.99949' stroke='#C9C9C9' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
            path(d='M2.26562 17.8359L10.3343 9.96145' stroke='#C9C9C9' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
  .buttons.Footer(:style="colorSecondaryComputed")
    .d-flex.justify-content-between
      b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="BackCalculed" :style="colorComputed")
        Back(size="24px")
        span(class="text-sizing-12") Atrás
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";

export default {
  name: "NoDate",

  components: {
    Header,
    Back
  },

  data: () => ({
    parentList: [],
    actionToShow: []
  }),

  created() {
    this.setLocalActions(this.menu);
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      menu: state => state.virtualrow.menu, //FIXME: Var loads older images in white
      currentSite: state => state.virtualrow.currentSite,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      secondColorBlue: state => state.virtualrow.secondColorBlue
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    siteName() {
      return this.currentSite?.name;
    }
  },
  methods: {
    ...mapActions({
      goBack: "virtualrow/goBack",
      setAction: "virtualrow/setAction"
    }),

    setLocalActions(actions) {
      console.log("actions");
      console.log(actions);
      console.log(this.menu);
      actions = JSON.parse(JSON.stringify(actions));
      for (let i = 0; i < actions.length; i++) {
        if (actions[i].hidden && typeof actions[i].hidden === "object") {
          actions[i].hidden =
            (actions[i].hidden.mobile && !this.isAtril) ||
            (actions[i].hidden.atril && this.isAtril);
        }
      }
      this.actionToShow = actions;
    },

    calculateAction(act) {
      if (this.isAtril) this.$store.dispatch("setTimer");
      if (act?.children) {
        this.parentList.push(this.actionToShow);
        return this.setLocalActions(act.children);
      }
      this.setAction(act);
    },

    BackCalculed() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      console.log("parentList");
      console.log(this.parentList);
      if (this.parentList.length) {
        this.setLocalActions(this.parentList.pop());
      } else this.goBack();
    }
  }
};
</script>

<style scoped>
.atril_text {
  font-size: 2rem;
}
.atril-mini {
  font-size: 1.3rem;
}
.NoDate {
  position: relative;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.NoDate > div {
  display: table-cell;
  vertical-align: middle;
}

.box-dk {
  max-width: 365px;
  margin: 1.4rem auto;
  text-align: center;
  display: block;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.3rem;
  width: 100%;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

.button {
  background-color: #0057a0;
  color: #fff;
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.buttons-dk {
  max-width: 296px;
  text-align: center;
  width: 100%;
  min-height: 20rem;
}

svg[fill="currentColor"] {
  width: 50px;
  height: 50px;
  /* fill: gray; */
}

button svg[fill="currentColor"] {
  /* fill: white; */
  width: auto;
  height: auto;
}

.dk-pos-dk {
  position: absolute;
  top: -30%;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.h1 {
  font-size: 2.8rem;
}

.fonterH3ColmedicaNoDate {
  width: 378px;
}

.arrowButton {
  background: #f4f4f4;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 15px;

  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  color: #3290e0;
  border: none;
  display: block;
  width: 100%;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -10%;
  }
}
</style>
